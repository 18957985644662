// angular
import { Injectable } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse
} from "@angular/common/http";

// rxjs
import { Observable } from "rxjs";
import { tap, finalize } from "rxjs/operators";

@Injectable()
export class LoggingInterceptor implements HttpInterceptor {
  intercept(
	request: HttpRequest<any>,
	next: HttpHandler
  ): Observable<HttpEvent<any>> {
	const START_TIME = Date.now();
	let status: string;

	return next.handle(request).pipe(
		tap(
		event => (status = event instanceof HttpResponse ? "succeeded" : ""),
		error => (status = "failed")
		),
		finalize(() => {
		const ELAPSED_TIME = Date.now() - START_TIME;
		const message = `${request.method} ${
			request.urlWithParams
		} ${status} in ${ELAPSED_TIME}ms`;

		this.logDetails(message, status);
		})
	);
  }

  private logDetails(message: string, status: string) {
	const color: string = status === "succeeded" ? "#00dba8" : "#ff0000";
	console.log(`%c ${message}`, `background: #4e006c; color: ${color}`);
  }
}

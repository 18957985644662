import { Injectable } from "@angular/core";
import { AppStoreService } from "./appStore.service";
import { IBillingAccount } from "../self-serve/models/product.model";
import { IAddress } from "../self-serve/models/account-details.model";
import { environment } from "src/environment";
import { IProduct } from "../self-serve/models/product.model";
import { Router } from "@angular/router";
@Injectable({
	providedIn: "root"
})

export class HelperService {
	constructor(private appStoreService: AppStoreService, private router: Router) { }

	public getBillingAccountById(billingAccountId: string): IBillingAccount {
		const billingAccounts = this.appStoreService.store.billingAccounts;
		return billingAccounts?.find((billingAccount) =>
			billingAccount.id === billingAccountId
		);
	}

	public getBillingAccountByBan(ban: string): IBillingAccount {
		const billingAccounts = this.appStoreService.store.billingAccounts;
		return billingAccounts?.find((billingAccount) =>
			billingAccount.accountNo === ban
		);
	}

	public getCompositeAddress(address: IAddress): string {
		let composite = address.country;
		composite = address.postCode + " " + composite;
		composite = address.state + " " + composite;
		composite = address.city + " " + composite;
		composite = address.street2 ? (address.street2 + " " + composite) : composite;
		composite = address.street1 + " " + composite;
		return composite;
	}

	public checkProductsTimestamp(): boolean {
		const currentTimeStamp = Math.ceil(Date.now() / 1000);
		return (this.appStoreService.store.productsTimestamp && (this.appStoreService.store.productsTimestamp + environment.productsTimeout) < currentTimeStamp);
	}

	public updateUsageSummary(): boolean {
		const currentTimeStamp = new Date().getTime();
		return (this.appStoreService.store.usageRequestTimestamp && (this.appStoreService.store.usageRequestTimestamp + environment.usageTimeout) < currentTimeStamp);
	}

	public getWebsiteHostServerURL(): string {
		return `${environment.websiteHostServer}`;
	}

	public findProduct(products: IProduct[], productId): IProduct {
		const selectedProduct = products?.find(
			(product) => product?.id === productId
		);
		return selectedProduct || undefined;
	}

	public pageRedirect(): void {
		this.router.navigate(["/page-not-found"]);
	}

	public scrollTop(): void {
		window.scroll({
			top: 0,
			left: 0,
			behavior: "smooth"
		});
	}

}

<!-- Beta Modal -->
<div
  class="modal fade"
  id="betaModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="betaModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="heading font-brand font-semibold">
          <span class="txt-color-primary">We're in </span
          ><span class="txt-color-secondary">BETA</span>
        </div>
        <div class="description p3 txt-black">
          While we don’t have all of the awesome new features in this version
          yet, you can still do a lot:

          <ul class="py3">
            <li>See your services in one place</li>
            <li>Monitor your usage</li>
            <li>View bills, both current and prior</li>
            <li>Make a secure payment with a credit or debit card</li>
            <li>Manage payment details</li>
          </ul>
        </div>
      </div>
      <div
        class="modal-footer txt-color-primary font-brand font-semibold txt-sm pb5"
        data-dismiss="modal"
      >
        OKAY GOT IT
      </div>
    </div>
  </div>
</div>

<!--Payment terms and conditions modal-->

<div
  class="modal fade"
  id="paymentTermsModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="paymentTermsModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body pb5">
        <div class="heading font-brand font-semibold">
          <span class="txt-color-primary">Primary Payment Terms </span>
        </div>
        <div class="description p3 txt-black">
          By selecting to make this your primary payment method, You have opted
          to authorise Dodo Services until further notice to arrange payments
          from your credit card to be debited with any amounts which Dodo may
          properly debit or charge you in accordance with our terms and
          conditions.
        </div>
      </div>
    </div>
  </div>
</div>

<!--Instalment Plan successful-->
<div
  class="modal fade"
  id="instalmentPlanUpdateSuccessModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="instalmentPlanUpdateSuccessModalLabel"
  aria-hidden="true"
  (click)="emptyInstalmentPlan()"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="emptyInstalmentPlan()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="modal-icon">
          <img src="../../../assets/images/modal/green-tick-circle.svg" />
        </div>
        <div class="heading">Automatic instalment plan review successful</div>
        <div class="content" *ngIf="selectedInstalmentValues && product">
          <div
            class="_service-selected p3"
            *ngIf="product.name === 'Electricity'"
          >
            <div class="__heading txt-grey-darker pb3">Instalment amount</div>
            <div
              class="__service pb2 font-brand txt-base txt-black font-medium"
            >
              ${{ selectedInstalmentValues.amount | number : "0.2-2" }}
            </div>
          </div>
          <div class="_service-selected p3" *ngIf="product.name === 'Gas'">
            <div class="__heading txt-grey-darker pb3">
              Summer Instalment amount
            </div>
            <div
              class="__service pb2 font-brand txt-base txt-black font-medium"
            >
              ${{ selectedInstalmentValues.amountSummer | number : "0.2-2" }}
            </div>
          </div>
          <div class="_service-selected p3" *ngIf="product.name === 'Gas'">
            <div class="__heading txt-grey-darker pb3">
              Winter Instalment amount
            </div>
            <div
              class="__service pb2 font-brand txt-base txt-black font-medium"
            >
              ${{ selectedInstalmentValues.amountWinter | number : "0.2-2" }}
            </div>
          </div>
          <div class="_amount p3">
            <div class="__heading txt-grey-darker pb3">Payment frequency</div>
            <div class="__value pb2">
              <span class="font-brand txt-base txt-black font-medium">{{
                selectedInstalmentValues.frequency.text
              }}</span>
            </div>
          </div>
          <div class="_payment-type-selected p3">
            <div class="__heading txt-grey-darker pb3">
              Next instalment date
            </div>
            <div class="__payment-method pb2 flex flex-align-items-center">
              <img
                class="_card-icon mr2"
                src="assets/images/icons/icon-datepicker.svg"
              />
              <span
                class="_card-details font-brand txt-base txt-black font-medium"
              >
                {{ selectedInstalmentValues.date | date : "longDate" }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer pt5">
        <button
          class="btn brand-btn-secondary"
          routerLink="/payment-details-overview"
          data-dismiss="modal"
          aria-label="Close"
          (click)="emptyInstalmentPlan()"
        >
          BACK TO PAYMENT DETAILS
        </button>
        <a
          class="_footer-text txt-lg font-semibold txt-grey-darkest font-brand py4"
          routerLink="/dashboard"
          data-dismiss="modal"
          aria-label="Close"
          (click)="emptyInstalmentPlan()"
        >
          Back to dashboard
        </a>
      </div>
    </div>
  </div>
</div>

<!--Delete Payment Method Modal-->

<div
  class="modal fade"
  id="deletePaymentMethodModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="deletePaymentMethodModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body pb5">
        <div class="heading font-brand font-semibold txt-color-primary">
          Are you sure you want to delete this payment type?
        </div>

        <div class="description p3 txt-black">
          <button
            class="block btn brand-btn-secondary py2"
            (click)="modalAction('delete')"
            data-dismiss="modal"
          >
            Yes
          </button>
          <div
            class="_cancel block txt-primary-color txt-base font-semibold font-brand my5"
          >
            <a data-dismiss="modal">
              <i class="fas fa-times mr1"></i><span>CANCEL</span></a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!--What happens if you submit instalment review modal-->
<div
  class="modal fade"
  id="whatHappensIfReviewInstalmentModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="whatHappensIfReviewInstalmentModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body pb5">
        <div class="heading font-brand font-semibold txt-color-primary">
          What happens once I have submitted?
        </div>

        <div class="description py5 txt-grey-darkest txt-base">
          <span class="block"
            >Once you have submitted, your instalment amount will be updated and
            deducted from your nominated account as per usual.</span
          >

          <button
            class="block btn brand-btn-secondary my5"
            data-dismiss="modal"
          >
            CLOSE
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Payment Plan / Extension successfully set up-->
<div
  class="modal fade"
  id="PaymentPlanUpdateSuccessModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="PaymentPlanUpdateSuccessModalLabel"
  aria-hidden="true"
  (click)="emptyPaymentPlan()"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="emptyPaymentPlan()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div
        class="modal-body"
        *ngIf="product && product.payment && selectedPaymentPlanValues"
      >
        <div class="modal-icon">
          <img src="../../../assets/images/modal/green-tick-circle.svg" />
        </div>
        <!--If payment extension-->
        <div
          class="heading"
          *ngIf="selectedPaymentPlanValues.planName === 'Payment Extension'"
        >
          Payment extension successfully set up!
        </div>
        <!--If payment plan-->
        <div
          class="heading"
          *ngIf="selectedPaymentPlanValues.planName === 'Payment Plan'"
        >
          Payment plan successfully set up!
        </div>
        <div class="content">
          <div class="_option-selected">
            <div class="row txt-grey-darker __heading">
              <div class="col-6 p-0">Service selected</div>
              <div class="col-6 p-0 txt-right-align">Balance owing</div>
            </div>
            <div class="row __details flex flex-align-items-center font-brand">
              <div class="col-6 p-0 flex flex-align-items-center ___name">
                <img
                  [src]="getIconPath(product.productGroupType)"
                  [alt]="product.name"
                  class="mr1"
                  *ngIf="product.productGroupType !== 'Energy'"
                />
                <img
                  [src]="getIconPath(product.name)"
                  [alt]="product.name"
                  class="mr1"
                  *ngIf="product.productGroupType === 'Energy'"
                />
                <span
                  class="txt-black txt-lg font-medium"
                  *ngIf="product.productGroupType !== 'Energy'"
                  >{{ product.productGroupType }}</span
                >
                <span
                  class="txt-black txt-lg font-medium"
                  *ngIf="product.productGroupType === 'Energy'"
                  >{{ product.name }}</span
                >
              </div>
              <div class="col-6 p-0 txt-color-primary font-semibold ___balance">
                ${{ product.accountBalance.amount.value | number : "0.2-2" }}
              </div>
            </div>
          </div>
          <div class="_option-selected">
            <div class="row txt-grey-darker __heading">
              <div class="col-6 p-0">Payment option</div>
            </div>
            <div class="row __details flex flex-align-items-center font-brand">
              <div class="col-12 p-0 flex flex-align-items-center ___name">
                <span class="txt-black txt-lg font-medium">{{
                  selectedPaymentPlanValues.planName
                }}</span>
              </div>
            </div>
          </div>
          <!--Only for payment extension-->
          <div
            class="_option-selected"
            *ngIf="selectedPaymentPlanValues.planName === 'Payment Extension'"
          >
            <div class="row txt-grey-darker __heading">
              <div class="col-6 p-0">Due date</div>
            </div>
            <div class="row __details flex flex-align-items-center font-brand">
              <div class="col-12 p-0 flex flex-align-items-center ___name">
                <span class="txt-black txt-lg font-medium">{{
                  selectedPaymentPlanValues.date | date : "longDate"
                }}</span>
              </div>
            </div>
          </div>
          <!--Only for payment extension ends-->

          <!--Only for payment plan-->
          <div
            class="_option-selected"
            *ngIf="selectedPaymentPlanValues.planName === 'Payment Plan'"
          >
            <div class="row txt-grey-darker __heading">
              <div class="col-6 p-0">Start date</div>
            </div>
            <div class="row __details flex flex-align-items-center font-brand">
              <div class="col-12 p-0 flex flex-align-items-center ___name">
                <span class="txt-black txt-lg font-medium">{{
                  selectedPaymentPlanValues.date | date : "longDate"
                }}</span>
              </div>
            </div>
          </div>
          <div
            class="_option-selected"
            *ngIf="selectedPaymentPlanValues.planName === 'Payment Plan'"
          >
            <div class="row txt-grey-darker __heading">
              <div class="col-6 p-0">Payment frequency</div>
            </div>
            <div class="row __details flex flex-align-items-center font-brand">
              <div class="col-12 p-0 flex flex-align-items-center ___name">
                <span class="txt-black txt-lg font-medium">
                  {{
                    selectedPaymentPlanValues.frequencyWithAmount.frequencyText
                  }}
                  payments of ${{
                    selectedPaymentPlanValues.frequencyWithAmount.proposedAmount
                      | number : "0.2-2"
                  }}
                </span>
              </div>
            </div>
          </div>
          <div
            class="_option-selected"
            *ngIf="selectedPaymentPlanValues.planName === 'Payment Plan'"
          >
            <div class="row txt-grey-darker __heading">
              <div class="col-6 p-0">Final Payment</div>
            </div>
            <div class="row __details flex flex-align-items-center font-brand">
              <div class="col-12 p-0 flex flex-align-items-center ___name">
                <span class="txt-black txt-lg font-medium">
                  {{
                    selectedPaymentPlanValues.finalPaymentDate
                      | date : "longDate"
                  }}
                </span>
              </div>
            </div>
          </div>
          <!--Only for payment extension ends-->
        </div>
      </div>
      <div class="modal-footer pt5">
        <button
          class="btn brand-btn-secondary"
          routerLink="/dashboard"
          data-dismiss="modal"
          aria-label="Close"
          (click)="emptyPaymentPlan()"
        >
          BACK TO DASHBOARD
        </button>
      </div>
    </div>
  </div>
</div>

<!--Payment plan eligibility fail modal-->

<div
  class="modal fade"
  id="PaymentPlanEligibilityFailModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="PaymentPlanEligibilityFailModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body pb5">
        <div class="modal-icon">
          <img src="../../../assets/images/modal/icon-sad-face.svg" />
        </div>
        <div
          class="heading font-brand font-semibold"
          *ngIf="selectedPaymentPlanEligibility && product"
        >
          <span class="txt-color-primary">
            {{ custErrorMsgPaymentPlanEligibility }}
          </span>
        </div>

        <div
          class="p5 txt-black txt-lg flex flex-justify-content-center flex-align-items-center description"
          *ngIf="custAndErrorMsgFlg"
        >
          <span class=""
            >Please contact us on 13 36 36 between 10AM - 7PM, Mon-Fri, AEST.We
            also recommend that read through our financial hardship policy which
            is available in the Terms and Policies of our website:
            <a href="https://www.dodo.com/legal/terms-policies" target="_blank"
              >https://www.dodo.com/legal/terms-policies</a
            >
          </span>
        </div>
        <div class="button py5">
          <button
            class="btn brand-btn-secondary"
            data-dismiss="modal"
            aria-label="Close"
          >
            CLOSE
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Payment extension : What happens if you still can't make payment modal-->
<div
  class="modal fade"
  id="whatHappensIfCannotMakePaymentModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="whatHappensIfCannotMakePaymentModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body pb5">
        <div class="heading font-brand font-semibold txt-color-primary">
          Payment
        </div>

        <div class="description py5 txt-grey-darkest txt-base">
          <span class="block"
            >If you are still unable to make your payment, please call 13DODO
            where one of our friendly customer representatives will be able to
            assist you.</span
          >

          <a
            class="block btn brand-btn-info my5"
            data-dismiss="modal"
            href="tel:+133636"
            >CALL DODO</a
          >
          <button
            class="block btn brand-btn-secondary my5"
            data-dismiss="modal"
          >
            OKAY, GOT IT
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Credit balance : What happens if you submit credit refund review modal-->
<div
  class="modal fade"
  id="whatHappensIfCreditBalanceModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="whatHappensIfCreditBalanceModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body pb5" *ngIf="product && product.creditRefund">
        <div class="heading font-brand font-semibold txt-color-primary">
          What happens next
        </div>

        <div class="description p5 txt-grey-darkest txt-base">
          <span class="block noheight">
            If you have a credit, we'll be able to refund it back to you.
          </span>
          <span class="block noheight mt3">
            Your refund will be processed into the credit card used to make the
            payment, ending in
            {{ product.creditRefund.primaryCard.details.lastFourDigits }}.
          </span>
          <span class="block noheight mt3">
            If you'd like your refund processed somewhere else, call us on 13 36
            36.
          </span>
          <span class="block noheight mt3">
            Refunds can take five business days.
          </span>

          <button
            class="block btn brand-btn-secondary my5"
            data-dismiss="modal"
          >
            CLOSE
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Credit balance : Submission under review-->
<div
  class="modal fade"
  id="creditBalanceRevieweSuccessModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="creditBalanceRevieweSuccessModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body" *ngIf="product && product.creditRefund">
        <div class="modal-icon">
          <img src="../../../assets/images/modal/green-tick-circle.svg" />
        </div>
        <div class="heading">We're reviewing your refund</div>
        <div class="content">
          <div class="_service-selected p3">
            <div class="__heading txt-grey-darker pb3">Service selected</div>
            <div
              class="__service pb2 font-brand txt-base txt-black font-medium"
            >
              <img
                [src]="getIconPath(product.name)"
                [alt]="product.name"
                class="mr1"
                *ngIf="product.productGroupType === 'Energy'"
              />

              <span
                class="txt-black txt-lg font-medium"
                *ngIf="product.productGroupType === 'Energy'"
                >{{ product.name }}</span
              >
            </div>
          </div>
          <div class="_service-selected p3">
            <div class="__heading txt-grey-darker pb3">Your credit balance</div>
            <div
              class="__service pb2 font-brand txt-base txt-black font-medium"
            >
              {{
                product.creditRefund.runningBalance.amount.value
                  | number : "0.2-2"
                  | currency
              }}
            </div>
          </div>
          <div class="_service-selected p3">
            <div class="__heading txt-grey-darker pb3">Refund amount</div>
            <div
              class="__service pb2 font-brand txt-base txt-black font-medium"
            >
              {{
                product.creditRefund.selectedBalance
                  | number : "0.2-2"
                  | currency
              }}
            </div>
          </div>
          <div
            class="txt-grey-darkest font-brand font-medium txt-base pb5 txt-center-align px5 pt5 lh-tight"
          >
            We'll send you an email to confirm your refund. This can take five
            business days.
          </div>
        </div>
      </div>
      <div class="modal-footer credit-balance-footer">
        <button
          class="btn brand-btn-secondary"
          routerLink="/dashboard"
          data-dismiss="modal"
          aria-label="Close"
          (click)="emptyInstalmentPlan()"
        >
          Back to dashboard
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Alert successfully set up Message-->
<div
  class="modal fade"
  id="UsageAlertUpdateSuccessModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="UsageAlertUpdateSuccessModal"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="modal-icon">
          <img src="../../../assets/images/modal/green-tick-circle.svg" />
        </div>
        <div class="heading">Your changes have been successfully saved.</div>

        <div class="content"></div>
      </div>
      <div class="modal-footer pt5">
        <button
          class="btn brand-btn-secondary"
          data-dismiss="modal"
          aria-label="Close"
        >
          OK
        </button>
      </div>
    </div>
  </div>
</div>

<!-- Alert failure set up Message-->
<div
  class="modal fade"
  id="UsageAlertUpdateFailureModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="UsageAlertUpdateFailureModal"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="alert-heading">Error</div>

        <div class="content">
          <span class="alert-content">
            If you are going to specify a new alert value, please ensure at
            least one type of alert is specified. Mobile or Email.</span
          >
        </div>
      </div>
      <div class="modal-footer pt5 pb-5">
        <button
          class="btn brand-btn-secondary"
          data-dismiss="modal"
          aria-label="Close"
        >
          OK
        </button>
      </div>
    </div>
  </div>
</div>

<!-- *** UnAuth Payment Extension Date Eligibility Check FAIL -->
<!--Payment plan eligibility fail modal-->

<div
  class="modal fade"
  id="PaymentPlanEligibilityFailModalUnAuth"
  tabindex="-1"
  role="dialog"
  aria-labelledby="PaymentPlanEligibilityFailModalLabelUnAuth"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body pb5">
        <div class="modal-icon">
          <img src="../../../assets/images/modal/icon-sad-face.svg" />
        </div>
        <div
          class="heading font-brand font-semibold"
          *ngIf="selectedPaymentPlanEligibilityauth && productunauth"
        >
          <span class="txt-color-primary">
            {{ custErrorMsgPaymentPlanEligibilityunauth }}
          </span>
        </div>

        <div
          class="p5 txt-black txt-lg flex flex-justify-content-center flex-align-items-center description"
          *ngIf="custAndErrorMsgFlgunauth"
        >
          <span class=""
            >Please contact us on 13 36 36 between 10AM - 7PM, Mon-Fri, AEST.We
            also recommend that read through our financial hardship policy which
            is available in the Terms and Policies of our website:
            <a href="https://www.dodo.com/legal/terms-policies" target="_blank"
              >https://www.dodo.com/legal/terms-policies</a
            >
          </span>
        </div>
        <div class="button py5">
          <a
            class="btn brand-btn-info mb4"
            data-dismiss="modal"
            aria-label="Close"
            [routerLink]="['/payment-helper']"
          >
            TAKE ME TO START
          </a>
          <button
            class="btn brand-btn-secondary"
            data-dismiss="modal"
            aria-label="Close"
          >
            CLOSE
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- *** UnAuth Payment Extension Date Eligibility Check Success-->
<!-- Payment Plan / Extension successfully set up Success -->
<div
  class="modal fade"
  id="PaymentPlanUpdateSuccessModalUnAuth"
  tabindex="-1"
  role="dialog"
  aria-labelledby="PaymentPlanUpdateSuccessModalUnAuthLabel"
  aria-hidden="true"
  (click)="emptyPaymentPlanUnAuth()"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
          (click)="emptyPaymentPlanUnAuth()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div
        class="modal-body"
        *ngIf="
          productunauth &&
          productunauth.payment &&
          selectedPaymentPlanValuesunauth
        "
      >
        <div class="modal-icon">
          <img src="../../../assets/images/modal/green-tick-circle.svg" />
        </div>
        <!--If payment extension-->
        <div
          class="heading"
          *ngIf="
            selectedPaymentPlanValuesunauth.planName === 'Payment Extension'
          "
        >
          Payment extension successfully set up!
        </div>
        <!--If payment plan-->
        <div
          class="heading"
          *ngIf="selectedPaymentPlanValuesunauth.planName === 'Payment Plan'"
        >
          Payment plan successfully set up!
        </div>
        <div class="content">
          <div class="_option-selected">
            <div class="row txt-grey-darker __heading">
              <div class="col-6 p-0">Service selected</div>
              <div class="col-6 p-0 txt-right-align">Balance owing</div>
            </div>
            <div class="row __details flex flex-align-items-center font-brand">
              <div class="col-6 p-0 flex flex-align-items-center ___name">
                <img
                  [src]="UnAuthgetIconPath(productunauth.productName)"
                  [alt]="productunauth.productName"
                  class="mr1"
                />
                <span class="txt-black txt-lg font-medium">{{
                  productunauth.productName
                }}</span>
              </div>
              <div class="col-6 p-0 txt-color-primary font-semibold ___balance">
                ${{ productunauth.currentBalance | number : "0.2-2" }}
              </div>
            </div>
          </div>
          <div class="_option-selected">
            <div class="row txt-grey-darker __heading">
              <div class="col-6 p-0">Payment option</div>
            </div>
            <div class="row __details flex flex-align-items-center font-brand">
              <div class="col-12 p-0 flex flex-align-items-center ___name">
                <span class="txt-black txt-lg font-medium">{{
                  selectedPaymentPlanValuesunauth.planName
                }}</span>
              </div>
            </div>
          </div>
          <!--Only for payment extension-->
          <div
            class="_option-selected"
            *ngIf="
              selectedPaymentPlanValuesunauth.planName === 'Payment Extension'
            "
          >
            <div class="row txt-grey-darker __heading">
              <div class="col-6 p-0">Due date</div>
            </div>
            <div class="row __details flex flex-align-items-center font-brand">
              <div class="col-12 p-0 flex flex-align-items-center ___name">
                <span class="txt-black txt-lg font-medium">{{
                  selectedPaymentPlanValuesunauth.date | date : "longDate"
                }}</span>
              </div>
            </div>
          </div>
          <!--Only for payment extension ends-->

          <!--Only for payment plan-->
          <div
            class="_option-selected"
            *ngIf="selectedPaymentPlanValuesunauth.planName === 'Payment Plan'"
          >
            <div class="row txt-grey-darker __heading">
              <div class="col-6 p-0">Start date</div>
            </div>
            <div class="row __details flex flex-align-items-center font-brand">
              <div class="col-12 p-0 flex flex-align-items-center ___name">
                <span class="txt-black txt-lg font-medium">{{
                  selectedPaymentPlanValuesunauth.date | date : "longDate"
                }}</span>
              </div>
            </div>
          </div>
          <div
            class="_option-selected"
            *ngIf="selectedPaymentPlanValuesunauth.planName === 'Payment Plan'"
          >
            <div class="row txt-grey-darker __heading">
              <div class="col-6 p-0">Payment frequency</div>
            </div>
            <div class="row __details flex flex-align-items-center font-brand">
              <div class="col-12 p-0 flex flex-align-items-center ___name">
                <span class="txt-black txt-lg font-medium">
                  {{
                    selectedPaymentPlanValuesunauth.frequencyWithAmount
                      .frequencyText
                  }}
                  payments of ${{
                    selectedPaymentPlanValuesunauth.frequencyWithAmount
                      .proposedAmount | number : "0.2-2"
                  }}
                </span>
              </div>
            </div>
          </div>
          <div
            class="_option-selected"
            *ngIf="selectedPaymentPlanValuesunauth.planName === 'Payment Plan'"
          >
            <div class="row txt-grey-darker __heading">
              <div class="col-6 p-0">Final Payment</div>
            </div>
            <div class="row __details flex flex-align-items-center font-brand">
              <div class="col-12 p-0 flex flex-align-items-center ___name">
                <span class="txt-black txt-lg font-medium">
                  {{
                    selectedPaymentPlanValuesunauth.finalPaymentDate
                      | date : "longDate"
                  }}
                </span>
              </div>
            </div>
          </div>
          <!--Only for payment extension ends-->
        </div>
      </div>
      <div class="modal-footer pt5">
        <button
          class="btn brand-btn-secondary"
          [routerLink]="['/payment-helper/select-services/']"
          data-dismiss="modal"
          aria-label="Close"
          (click)="emptyPaymentPlanUnAuth()"
        >
          BACK TO SERVICE SELECTION
        </button>
      </div>
    </div>
  </div>
</div>

<!--Delete Mailbox Modal-->
<div
  class="modal fade"
  id="deleteMailBoxModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="deleteMailBoxModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body pb5">
        <div class="heading font-brand font-semibold txt-color-primary">
          Are you sure you want to delete this mailbox?
        </div>

        <div class="description p3 txt-black">
          <button
            class="block btn brand-btn-secondary py2"
            (click)="modalAction('delete')"
            data-dismiss="modal"
          >
            Yes
          </button>
          <div
            class="_cancel block txt-primary-color txt-base font-semibold font-brand my5"
          >
            <a data-dismiss="modal">
              <i class="fas fa-times mr1"></i><span>CANCEL</span></a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  class="modal fade"
  id="PrimaryMethodEligibilityFailModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="PrimaryMethodEligibilityFailModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body pb5">
        <div class="modal-icon">
          <img src="../../../assets/images/modal/icon-sad-face.svg" />
        </div>
        <div class="heading font-brand font-semibold">
          <span class="txt-color-primary">
            Your previously saved primary payment method is no longer valid. To
            request a payment extension, Please add a new payment method.
          </span>
        </div>
        <div class="button py5" *ngIf="billingAccountId">
          <a
            class="btn brand-btn-info mb4"
            data-dismiss="modal"
            aria-label="Close"
            [routerLink]="['/payment-details', billingAccountId]"
          >
            Update payment type
          </a>
          <button
            class="btn brand-btn-secondary"
            data-dismiss="modal"
            aria-label="Close"
          >
            CLOSE
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<!--Concession card Failure Model-->

<div
  class="modal fade"
  id="concessionCardModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="concessionCardModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div
          class="description font-brand font-semibold flex flex-justify-content-between flex-align-items-center"
        >
          <span class="txt-color-primary"
            >Oops! Something went wrong while saving the details. Please try
            again.</span
          >
        </div>
        <button
          type="button"
          class="close heading-bottom"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
  </div>
</div>

<!--Concession card Success Model-->

<div
  class="modal fade"
  id="concessionCardSuccessModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="concessionCardSuccessModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <div
          class="description font-brand font-semibold flex flex-justify-content-between flex-align-items-center"
        >
          <span class="txt-color-primary"
            >Your details have been successfully saved!</span
          >
        </div>
        <button
          type="button"
          class="close heading-bottom"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>
  </div>
</div>

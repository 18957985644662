import { MaskedFormFieldComponent } from "./masked-form-field/masked-form-field.component";
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";

// components
import { NavigationComponent } from "./navigation/navigation.component";
import { FooterComponent } from "./footer/footer.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { BadgeComponent } from "./badge/badge.component";
import { ProgressBarComponent } from "./progress-bar/progress-bar.component";
import { ButtonComponent } from "./button/button.component";
import { ModalComponent } from "./modal/modal.component";
import { BreadcrumbComponent } from "./breadcrumb/breadcrumb.component";
import { SignoutComponent } from "./signout/signout.component";
import { NoticeComponent } from "./notice/notice.component";
import { MobileSecondNavComponent } from "./mobile-second-nav/mobile-second-nav.component";
import { FileUploadComponent } from "./file-upload/file-upload.component";
import { PaginationComponent } from "./pagination/pagination.component";
import { TealiumModule } from "src/tealium/tealium.module";
import { MyNavigationComponent } from "./my-navigation/my-navigation.component";
import { MyFooterComponent } from "./my-footer/my-footer.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
// Material components
import { MatInputModule } from "@angular/material/input";
import { MaintenanceComponent } from "./maintenance/maintenance.component";
import { DodoDirectivesModule } from "./directives/directives.module";
import { MaterialModule } from "./material-form/material.module";
import { InfoDialogLayoutComponent } from "./info-dialog-layout/info-dialog-layout.component";
import { RecaptchaFormsModule, RecaptchaModule } from "ng-recaptcha";
import { MaskitoDirective } from "@maskito/angular";
@NgModule({
	imports: [
		CommonModule,
		RouterModule,
		TealiumModule,
		FormsModule,
		ReactiveFormsModule,
		MatInputModule,
		DodoDirectivesModule,
		MaterialModule,
		RecaptchaModule,
		RecaptchaFormsModule,
		MaskitoDirective
	],
	declarations: [
		NavigationComponent,
		FooterComponent,
		MaskedFormFieldComponent,
		PageNotFoundComponent,
		BadgeComponent,
		ProgressBarComponent,
		ButtonComponent,
		ModalComponent,
		BreadcrumbComponent,
		SignoutComponent,
		NoticeComponent,
		MobileSecondNavComponent,
		FileUploadComponent,
		PaginationComponent,
		MyNavigationComponent,
		MyFooterComponent,
		MaintenanceComponent,
		InfoDialogLayoutComponent

	],
	exports: [
		NavigationComponent,
		FooterComponent,
		PageNotFoundComponent,
		BadgeComponent,
		ProgressBarComponent,
		ButtonComponent,
		BreadcrumbComponent,
		ModalComponent,
		SignoutComponent,
		NoticeComponent,
		MobileSecondNavComponent,
		FileUploadComponent,
		PaginationComponent,
		MyNavigationComponent,
		MyFooterComponent,
		MaintenanceComponent,
		MaskedFormFieldComponent,
		InfoDialogLayoutComponent,
		RecaptchaModule,
		RecaptchaFormsModule
	]
})
export class CommomComponentModule {}

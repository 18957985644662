import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { HttpService } from "src/libraries/api/services/http.service";

import {
	IPayment,
	IPaymentMethod,
	IPaymentIPSI,
	IPaymentSubmitMessage,
	IPaymentResponse
} from "../models/payment.model";

import { Observable } from "rxjs";
import { environment } from "src/environment";
import { IPaymentPlanHistoryResponse } from "../models/payment-plan.model";
import { map } from "rxjs/operators";

@Injectable({
	providedIn: "root"
})
export class PaymentService {
	constructor(
		private httpService: HttpService,
		private httpClient: HttpClient
	) { }

	getPayment(billingAccountId): Observable<IPayment> {
		const url = `${environment.apiURL
			}/api/v1/paymentmgmt/payment/paymentMethod?billingAccountId=${billingAccountId}`;
		return this.httpService.get<IPayment>(url);
	}

	getPreferredtMethod(methods: IPaymentMethod[]) {
		let preferred: any = false;
		if (methods === undefined || methods.length === 0) {
			return preferred;
		}
		methods.map(method => {
			if (method.preferred && method.authorized && method.status === "Active") {
				preferred = method;
			}
		});
		return preferred;
	}

	selectPaymentMethod(methods: IPaymentMethod[], paymentmethodId) {
		let selectedMethod = <IPaymentMethod>{};
		if (methods === undefined || methods.length === 0) {
			return selectedMethod;
		}
		methods.map(method => {
			if (method.id === "" + paymentmethodId) {
				selectedMethod = method;
				selectedMethod.selected = true;
			} else {
				method.selected = false;
			}
		});

		return selectedMethod;
	}

	getIpsiUrl(data): Observable<IPaymentIPSI> {
		const url = `${environment.apiURL}/api/v1/paymentmgmt/payment/embededUri`;
		return this.httpClient.post<IPaymentIPSI>(url, data);
	}

	postPayment(data): Observable<IPaymentResponse> {
		const url = `${environment.apiURL}/api/v1/paymentmgmt/payment`;
		return this.httpClient.post<IPaymentResponse>(url, data);
	}

	get1PPaymentHistory(billingAccountId): Observable<IPaymentPlanHistoryResponse[]> {
		const url = `${environment.apiURL}/api/v1/paymentmgmt/paymentPlan?billingAccountId=${billingAccountId}`;
		return this.httpClient.get<IPaymentPlanHistoryResponse[]>(url).pipe(map((response) => response));
	}

	// OP backend not ready for this function yet
	postIpsiPayment(customerId, data) {
		const url = `${environment.apiURL}/api/v1/paymentmethodmgmt/customer/${customerId}/paymentmethod`;
		return this.httpClient.post(url, data);
	}

	patchPaymentMethod(billingAccountId, data) {
		const url = `${environment.apiURL}/api/v1/paymentmgmt/payment/paymentMethod?billingAccountId=${billingAccountId}`;
		return this.httpClient.patch(url, data);
	}

	getDodoPayment(customerId): Observable<IPayment> {
		const url = `${environment.apiUrlDodo}/exp-dodoselfservice/api/v1/paymentmethodmgmt/customer/${customerId}/paymentmethod`;
		return this.httpService.getSlience(url);
	}

	getDodoIpsiUrl(customerId): Observable<IPaymentIPSI> {
		const url = `${environment.apiUrlDodo}/exp-dodoselfservice/api/v1/paymentmgmt/customer/${customerId}/creditCardIframeUrl?appName=mydodo`;
		return this.httpClient.get<IPaymentIPSI>(url);
	}

	postDodoPaymentDetails(customerId, data): Observable<IPaymentSubmitMessage> {
		const url = `${environment.apiUrlDodo}/exp-dodoselfservice/api/v1/paymentmgmt/customer/${customerId}/payment`;
		return this.httpClient.post<IPaymentSubmitMessage>(url, data);
	}

	postDodoIpsiPayment(customerId, data) {
		const url = `${environment.apiUrlDodo}/exp-dodoselfservice/api/v1/paymentmethodmgmt/customer/${customerId}/paymentmethod`;
		return this.httpClient.post(url, data);
	}

	patchDodoNewPrimaryMethod(customerId, paymentMethodId, data) {
		const url = `${environment.apiUrlDodo}/exp-dodoselfservice/api/v1/paymentmethodmgmt/customer/${customerId}/paymentmethod/${paymentMethodId}`;
		return this.httpClient.patch(url, data);
	}

	// *** UnAuth CUSTOMER IPSI CODE to get IPSI url
	getIpsiUrlUnAuth(customerId, amountToPay): Observable<IPaymentIPSI> {
		const url = `${environment.apiUrlDodo}/exp-publicselfservice/api/v1/paymentmgmt/customer/${customerId}/creditCardIframeUrl?appName=mydodo&amountToPay=${amountToPay}`;
		return this.httpClient.get<IPaymentIPSI>(url);
	}

	// *** POST, if IPSI card transaction is successful
	postPaymentDetailsUnAuth(
		customerId,
		data
	): Observable<IPaymentSubmitMessage> {
		const url = `${environment.apiUrlDodo}/exp-publicselfservice/api/v1/paymentmgmt/customer/${customerId}/payment`;
		return this.httpClient.post<IPaymentSubmitMessage>(url, data);
	}

	// new services payments
	makepayment(data): Observable<any> {
		const url = `${environment.apiURL}/api/v1/paymentmgmt/payment`;
		return this.httpClient.post(url, data);
	}
	// new services payments

	// new services payments
	quoteUpdate(data): Observable<any> {
		const url = `${environment.apiURL}/api/v1/quoteMgmt/order`;
		return this.httpClient.post(url, data);
	}
	// new services payments

}

// angular
import { Injectable, Optional } from "@angular/core";
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent
} from "@angular/common/http";

// rxjs
import { Observable } from "rxjs";
import { timeout } from "rxjs/operators";
import { APIConfiguration } from "../models/api.models";
import { environment } from "src/environment";

const DEFAULT_TIMEOUT = 1 * 2.5;

@Injectable()
export class TimeOutInterceptor implements HttpInterceptor {
  private apiConfig: APIConfiguration;

  constructor(@Optional() apiConfig: APIConfiguration) {
	if (apiConfig) {
		this.apiConfig = apiConfig;
	}
  }

  intercept(
	request: HttpRequest<any>,
	next: HttpHandler
  ): Observable<HttpEvent<any>> {
	return next
		.handle(request)
		.pipe(timeout(environment.timeout));
  }
}

import { Injectable } from "@angular/core";
import { PageViewData, EventTrackingData } from "../model/page-data.model";
import { AppStoreService } from "src/app/services/appStore.service";
import { AppError } from "src/app/models/error.model";
import { ISelectedPayment } from "src/app/self-serve/models/store.model";
import { FIELD_VALIDATION, FLASH_MESSAGES, MODAL_MESSAGES, HTTP_ERROR_PAGES, API_ERROR_PAGES } from "../enums/telium.enum";
import { FLASH_TYPES, MODAL_TYPES } from "src/tealium/enums/telium.enum";
import { FeatureFlagService } from "src/app/services/feature-flag.service";
import { FeatureFlag } from "src/app/services/feature-flags.enum";

@Injectable({
	providedIn: "root"
})
export class TealiumService {

	constructor(
		private appStoreService: AppStoreService,
		private readonly featureFlagService: FeatureFlagService
	) { }

	private pageViewData = new PageViewData();
	private currentPagePath: string;
	private payment: ISelectedPayment = undefined;
	eventActionMakeAppointment = "Appointment | Make | Steps";
	eventActionRescheduleAppointment = "Appointment | Reschedule | Steps";
	eventActionChangeAppointment = "Appointment | Change | Steps";

	private static formatDataForTealium(
		object,
		defaultValue = "",
		convertToLowerCase = false
	) {
		if (typeof object === "undefined" || object === null) {
			return {};
		}
		if (Array.isArray(object)) {
			return object.map(obj =>
				this.formatDataForTealium(obj, defaultValue, convertToLowerCase)
			);
		}
		if (typeof object !== "object") {
			return this.formatValueForTealium(
				object,
				defaultValue,
				convertToLowerCase
			);
		}
		Object.keys(object).forEach(
			key => object[key] == null && delete object[key]
		);
		return Object.keys(object).reduce((newObj, key) => {
			const newVal = TealiumService.formatDataForTealium(
				object[key],
				defaultValue,
				convertToLowerCase
			);
			newObj[key] = newVal;
			return newObj;
		}, {});
	}

	private static formatValueForTealium(
		value,
		defaultValue = "",
		convertToLowerCase = false
	) {
		if (Array.isArray(value)) {
			return value.map(obj =>
				this.formatValueForTealium(obj, defaultValue, convertToLowerCase)
			);
		}
		if (typeof value === "boolean") {
			return value ? "1" : "0";
		}
		if (typeof value === "undefined" || value === null) {
			return defaultValue;
		}
		let formattedValue = "";
		if (typeof value === "object") {
			return JSON.stringify(value);
		} else {
			formattedValue = value.toString().trim();
			if (formattedValue === "") {
				return defaultValue;
			}
			if (convertToLowerCase) {
				return formattedValue.toLowerCase();
			}
		}
		return formattedValue;
	}

	activateSim(result: any) {
		let eventResult = "fail";
		if ("true" === result["status"]) { eventResult = "success"; }
		this.trackPageEvent(null, {
			tealium_event: "activate_sim",
			result: eventResult
		});
	}

	public changeNbnPlan(newPlan: string, oldPlan: string) {
		this.trackPageEvent(null, {
			tealium_event: "Change Plan Success",
			event_trigger: "change_plan_success",
			product_category: this.pageViewData.product_category,
			product_subcategory: this.pageViewData.product_subcategory,
			product_id: newPlan,
			product_id_changed: oldPlan
		});
	}

	trackPageEvent(event: MouseEvent, data: object) {
		const trackingData = new EventTrackingData();
		Object.assign(trackingData, data);

		switch (trackingData.tealium_event) {
			case "zone_link":
				// Activate SIM button insdie Product tile could cause wrong zone tracking
				if (trackingData.page_zone === "Product Tile") {
					let parentElement: Element = event["toElement"];
					while (parentElement) {
						if (parentElement.id === "activateSimModal") {
							return;
						} else {
							parentElement = parentElement.parentElement;
						}
					}
				}
				break;
			case "button_click":
				if (!trackingData.link_text) {
					Object.assign(trackingData, {
						link_text: event.currentTarget["innerText"]
					});
				}
				break;
			case "faq_tracking":
				break;
			case "activate_sim":
				trackingData.link_text = data["result"];
				break;
		}

		this.link(trackingData);
	}

	trackPageView(pagePath) {
		this.pageViewData = new PageViewData();
		this.pageViewData.tealium_event = "basic_page_view";
		this.pageViewData.previous_page_path =
			this.currentPagePath || document.referrer;

		this.currentPagePath = window.location.origin + window.location.pathname;
		this.populateCustomerInfo();
		this.populatePageDetails(pagePath);
		this.populateProducts();

		this.view(TealiumService.formatDataForTealium(this.pageViewData));
	}

	private populateCustomerInfo() {
		if (!this.appStoreService.store || !this.appStoreService.store.customer) {
			return;
		}

		this.pageViewData.customer_email = this.appStoreService.store.customer.email;
		this.pageViewData.customer_service_id = this.appStoreService.store.customer.accountId
			|| this.appStoreService.store.customer.customerId;
	}

	private populatePageDetails(pagePath) {
		const path = pagePath.split("/");
		if (path.length < 2) {
			return;
		}

		switch (path[1]) {
			// Obsolete from auth0 release
			case "login":
				this.pageViewData.page_id = "MY1";
				if (path.length > 2) {
					switch (path[2]) {
						case "forgot-pwd":
							this.pageViewData.page_id = "MY15";
							break;
						case "change-pwd":
							this.pageViewData.page_id = "MY16";
							break;
					}
				}
				break;
			//
			case "login-email": // auth0 migration
				this.pageViewData.page_id = "MY17";
				break;
			case "forgot-pwd":
				this.pageViewData.page_id = "MY15";
				break;
			case "":
			case "dashboard":
				this.pageViewData.page_id = "MY2";
				if (path.length > 2) {
					switch (path[2]) {
						case "product":
							this.pageViewData.page_id = "MY3";
							this.getProductDetails(path[3]); // pass product id
							break;
						case "nbn-change-plan":
							this.pageViewData.page_id = "MY25";
							this.getProductDetails(path[3]); // pass product id
							break;
						case "usage-summary":
							this.pageViewData.page_id = "MY4";
							this.getProductDetails(path[3]); // pass product id
							break;
						case "addons-summary":
							this.pageViewData.page_id = "MY14";
							this.getProductDetails(path[3]);
							break;
						case "usage-details":
							this.pageViewData.page_id = "MY20";
							if (path.length > 2) {
								switch (path[3]) {
									case "data":
										this.pageViewData.page_id = "MY21";
										break;
									case "international":
										this.pageViewData.page_id = "MY22";
										break;
									case "other":
										this.pageViewData.page_id = "MY23";
										break;
								}
								this.getProductDetails(path[4]);
							}
							break;
						case "upcoming-changes":
							this.pageViewData.page_id = "MY95";
							this.pageViewData.product_nbn_cohort_id = this.appStoreService.store.migrationSelected.migration_cohort;
							break;
					}
				}
				break;
			case "billing-overview":
				this.pageViewData.page_id = "MY5";
				if (path.length > 2) {
					this.pageViewData.page_id = "MY6";
					this.getProductDetails(path[3]);
				}
				break;
			case "payment-details-overview":
				this.pageViewData.page_id = "MY7";
				if (path.length > 2) {
					this.pageViewData.page_id = "MY8";
					this.getProductDetails(path[2]);
				}
				break;
			case "make-a-payment":
				this.pageViewData.page_id = "MY9";
				if (path.length > 2) {
					switch (path[2]) {
						case "":
							this.pageViewData.page_id = "MY9";
							break;
						case "payment-amount":
							this.pageViewData.page_id = "MY10";
							break;
						case "payment-type":
							this.pageViewData.page_id = "MY11";
							this.populatePaidInstalment();
							break;
						case "payment-summary":
							this.pageViewData.page_id = "MY12";
							this.populatePaidInstalment();
							break;
						case "payment-successful":
							this.pageViewData.page_id = "MY13";
							this.populatePaidInstalment();
							break;
						default:
							this.pageViewData.page_id = "MY00";
					}
					this.getProductDetails(path[3]);
				}
				break;
			case "usage-alert":
				this.pageViewData.page_id = "MY24";
				if (path.length > 2) { this.getProductDetails(path[2]); }
				break;
			case "payment-help":
				this.pageViewData.page_id = "MY30";
				if (path.length > 2) {
					switch (path[2]) {
						case "get-started":
							this.pageViewData.page_id = "MY31";
							break;
						case "select-service":
							this.pageViewData.page_id = "MY32";
							break;
						case "payment-plan-extension":
							this.pageViewData.page_id = "MY33";
							break;
						case "payment-plan-options":
							this.pageViewData.page_id = "MY34";
							break;
						case "payment-plan-confirmation":
							this.pageViewData.page_id = "MY35";
							break;
						case "payment-extension-options":
							this.pageViewData.page_id = "MY36";
							break;
						case "payment-extension-confirmation":
							this.pageViewData.page_id = "MY37";
							break;
					}
					this.getProductDetails(path[3]);
				}
				break;
			case "instalment-plan":
				this.pageViewData.page_id = "MY40";
				if (path.length > 2) {
					switch (path[2]) {
						case "intro":
							this.pageViewData.page_id = "MY41";
							break;
						case "update":
							this.pageViewData.page_id = "MY42";
							break;
						case "summary":
							this.pageViewData.page_id = "MY43";
							break;
					}
					this.getProductDetails(path[3]);
				}
				break;
			case "credit-balance":
				this.pageViewData.page_id = "MY45";
				if (path.length > 3) {
					this.getProductDetails(path[3]);
					switch (path[2]) {
						case "refund-amount":
							this.pageViewData.page_id = "MY47";
							break;
						case "refund-summary":
							this.pageViewData.page_id = "MY48";
							break;
						default:
							this.pageViewData.page_id = "MY46";
							this.getProductDetails(path[2]);
					}
				}
				break;
			case "account-introduction":
				this.pageViewData.page_id = "MY50";
				if (path.length > 3) {
					this.getProductDetails(path[3]);
					switch (path[2]) {
						case "account-personal":
							this.pageViewData.page_id = "MY51";
							break;
					}
				}
				break;
			case "mail-box":
				this.pageViewData.page_id = "MY52";
				if (path.length > 3) {
					this.getProductDetails(path[3]);
					switch (path[2]) {
						case "mailbox-list":
							this.pageViewData.page_id = "MY53";
							break;
						case "mailbox-edit":
							this.pageViewData.page_id = "MY54";
							break;
						case "mailbox-add":
							this.pageViewData.page_id = "MY55";
							break;
					}
				}
				break;
			case "subscription":
				this.pageViewData.page_id = "MY56";
				if (path.length > 2) { this.getProductDetails(path[2]); }
				break;
			case "order-status":
				this.pageViewData.page_id = "MY60";
				if (path.length > 2) { this.pageViewData.page_id = "MY61"; }
				break;
			case "pay-my-bill":
				this.pageViewData.page_id = "MY62";
				break;
			case "payment-extension":
				this.pageViewData.page_id = "MY63";
				break;
			case "pay-bill":
				this.pageViewData.page_id = "MY64";
				break;
			case "view-bill":
				this.pageViewData.page_id = "MY65";
				break;
			case "dodo-account-status":
				this.pageViewData.page_id = "MY66";
				break;
			case "order-status-show":
				this.pageViewData.page_id = "MY67";
				break;
			case "select-service":
				this.pageViewData.page_id = "MY70";
				if (path.length > 2) {
					switch (path[2]) {
						case "payment-amount":
							this.pageViewData.page_id = "MY71";
							break;
						case "payment-type":
							this.pageViewData.page_id = "MY72";
							break;
						case "payment-successful":
							this.pageViewData.page_id = "MY73";
							break;
					}
				}
				break;
			case "select-services":
				this.pageViewData.page_id = "MY76";
				if (path.length > 2) {
					switch (path[2]) {
						case "payment-amount":
							this.pageViewData.page_id = "MY77";
							break;
						case "payment-type":
							this.pageViewData.page_id = "MY78";
							break;
						case "payment-successful":
							this.pageViewData.page_id = "MY79";
							break;
					}
				}
				break;
			case "payment-helper":
				this.pageViewData.page_id = "MY80";
				if (path.length > 2) {
					switch (path[2]) {
						case "get-started":
							this.pageViewData.page_id = "MY81";
							break;
						case "select-services":
							this.pageViewData.page_id = "MY82";
							break;
						case "payment-plan-extension":
							this.pageViewData.page_id = "MY83";
							break;
						case "payment-plan-options":
							this.pageViewData.page_id = "MY84";
							break;
						case "payment-plan-confirmation":
							this.pageViewData.page_id = "MY85";
							break;
						case "payment-extension-options":
							this.pageViewData.page_id = "MY86";
							break;
						case "payment-extension-confirmation":
							this.pageViewData.page_id = "MY87";
							break;
					}
				}
				break;
			case "support-request-overview":
				this.pageViewData.page_id = "MY90";
				if (path.length > 2) {
					switch (path[2]) {
						case "list":
							this.pageViewData.page_id = "MY91";
							break;
						case "create":
							this.pageViewData.page_id = "MY92";
							break;
						case "details":
							this.pageViewData.page_id = "MY93";
							break;
						case "success":
							this.pageViewData.page_id = "MY94";
							this.pageViewData.tealium_event = "support_request_success";
					}
					this.getProductDetails(path[3]);
				}
				break;
			case "":
				break;
			default:
				this.pageViewData.page_id = "MY0";
		}
	}

	private getProductDetails(productId: string) {
		if (!productId) { return; }

		let selected: any = false;
		this.appStoreService.store.products.map(product => {
			if (product.id === productId) {
				selected = product;
				this.pageViewData.product_category =
					"Internet" === selected.productGroupType
						? "Broadband"
						: selected.productGroupType;
				this.pageViewData.product_subcategory = selected.name;
			}
		});
	}

	private populateProducts() {
		if (this.appStoreService.store.products) {
			this.pageViewData.customer_active_products = new Array();
			this.pageViewData.customer_inactive_products = new Array();
			this.appStoreService.store.products.forEach(product => {
				if ("Active" === product.status) {
					this.pageViewData.customer_active_products.push(product.name);
				} else { this.pageViewData.customer_inactive_products.push(product.name); }
			});
		}
	}

	private populatePaidInstalment() {
		this.payment = this.appStoreService.store.customer.payment;
		if (!this.payment) {
			return;
		}
		this.pageViewData.instalment_paid = this.payment.instalment_paid;
	}

	error(error: AppError) {
		const trackData = new EventTrackingData();
		trackData.tealium_event = "error_event";

		const errorCode = error.apiError ? "API_" +
			error.apiError?.status || "ERROR" + "-" + error.apiError?.code || ""
			: "";

		Object.assign(trackData, {
			error_code: errorCode,
			error_description: error.message
		});

		this.link(trackData);
	}

	view(data?: any) {
		this.track("view", data);
	}

	link(data?: any) {
		this.track("link", TealiumService.formatDataForTealium(data));
	}

	// Data layer is optional set of key/value pairs
	private track(tealium_event: string, data?: any): void {
		try {
			(<any>window).utag.track(tealium_event, data);
		} catch (error) {
			console.log("UTAG::" + error.error);
		}
	}

	trackInvoiceDownloadBtnclick() {
		this.trackPageEvent(null, {
			tealium_event: "invoice_download"
		});
	}

	trackInvoiceloader() {
		this.trackPageEvent(null, {
			tealium_event: "invoice_view"
		});
	}

	private trackSelectedAppointment(event_action: string, event_label: string) {
		this.trackPageEvent(null, {
			tealium_event: "set_appointment",
			event_category: "Form",
			event_action: event_action,
			event_label: event_label
		});
	}

	trackAppointment(appointment_case: string, appointment_status: string) {
		switch (appointment_case) {
			case "scheduled":
				this.trackSelectedAppointment(this.eventActionMakeAppointment, "Make Appointment");
				break;
			case "rescheduled":
				const event_label = "Change Appointment";
				if (appointment_status === "past") {
					this.trackSelectedAppointment(this.eventActionRescheduleAppointment, event_label);
				} else {
					this.trackSelectedAppointment(this.eventActionChangeAppointment, event_label);
				}
				break;
		}
	}

	// track More Info page click for Change Appointment Past and Future Booked Appointments Dts.
	trackMoreInfoAppointment(appointment_status) {
		const event_label = "Here's some more information";
		switch (appointment_status) {
			case "past":
				this.trackSelectedAppointment(this.eventActionRescheduleAppointment, event_label);
				break;
			case "future":
				this.trackSelectedAppointment(this.eventActionChangeAppointment, event_label);
				break;
		}
	}

	// track confirm btn for selection of new appointment
	trackConfirmAppointment(appointment_case: string, appointment_status: string) {
		const event_label = "Confirm";
		switch (appointment_case) {
			case "scheduled":
				this.trackSelectedAppointment(this.eventActionMakeAppointment, event_label);
				break;
			case "rescheduled":
				if (appointment_status === "past") {
					this.trackSelectedAppointment(this.eventActionRescheduleAppointment, event_label);
				} else {
					this.trackSelectedAppointment(this.eventActionChangeAppointment, event_label);
				}
				break;
		}
	}

	// track YES, CONFIRM btn for Final re-confirmation
	trackReconfirmAppointment(appointment_case: string, appointment_status: string) {
		const event_label = "Yes, Confirm";
		switch (appointment_case) {
			case "scheduled":
				this.trackSelectedAppointment(this.eventActionMakeAppointment, event_label);
				break;
			case "rescheduled":
				if (appointment_status === "past") {
					this.trackSelectedAppointment(this.eventActionRescheduleAppointment, event_label);
				} else {
					this.trackSelectedAppointment(this.eventActionChangeAppointment, event_label);
				}
				break;
		}
	}

	// track error trying to reschedule
	trackErrorAppointment(appointment_case: string, appointment_status: string) {
		const event_label = "You can only change your appointment online twice. Please submit a support request to arrange a new appointment.";
		switch (appointment_case) {
			case "scheduled":
				this.trackSelectedAppointment("Appointment | Make | Error", event_label);
				break;
			case "rescheduled":
				if (appointment_status === "past") {
					this.trackSelectedAppointment("Appointment | Reschedule | Error", event_label);
				} else {
					this.trackSelectedAppointment("Appointment | Change | Error", event_label);
				}
				break;
		}
	}

	// track successful reschedule
	trackSuccessAppointment(appointment_case: string, appointment_status: string) {
		const event_label = "Success";
		switch (appointment_case) {
			case "scheduled":
				this.trackSelectedAppointment(this.eventActionMakeAppointment, event_label);
				break;
			case "rescheduled":
				if (appointment_status === "past") {
					this.trackSelectedAppointment(this.eventActionRescheduleAppointment, event_label);
				} else {
					this.trackSelectedAppointment(this.eventActionChangeAppointment, event_label);
				}
				break;
		}
	}

	trackPrePostMigrationBanner(migration_cohort, banner_message) {
		this.trackPageEvent(null, {
			tealium_event: "flash_message",
			event_trigger: "banner",
			migration_cohort: migration_cohort,
			banner_message: banner_message
		});
	}

	trackSubmitPaymentHelp(pagePath) {
		this.pageViewData = new PageViewData();
		this.pageViewData.event_trigger = "form_success";
		this.pageViewData.tealium_event = "Payment Extension Success";
		this.pageViewData.previous_page_path =
			this.currentPagePath || document.referrer;

		this.populateCustomerInfo();
		this.populatePageDetails(pagePath);

		this.view(TealiumService.formatDataForTealium(this.pageViewData));
	}

	public trackFieldValidationErrors(errorDetails: any): void {
		this.trackPageEvent(null, {
			event_trigger: FIELD_VALIDATION.EVENT_TRIGGER,
			type: FIELD_VALIDATION.TYPE,
			error_code: errorDetails.controlName,
			error_message: errorDetails.errorMessage
		});
	}

	private trackFlashMessages(errorDetails: any): void {
		if (errorDetails.errorCode === undefined) {
			this.trackPageEvent(null, {
				event_trigger: FLASH_MESSAGES.EVENT_TRIGGER,
				flash_type: errorDetails.flashType, // (success, warning, notify, error)
				flash_message: errorDetails.flashMessage
			});
		} else {
			this.trackPageEvent(null, {
				event_trigger: FLASH_MESSAGES.EVENT_TRIGGER,
				flash_type: errorDetails.flashType,  // (success, warning, notify, error)
				flash_message: errorDetails.flashMessage,
				error_code: errorDetails.errorCode
			});
		}
	}

	private trackModalMessages(errorDetails: any): void {
		if (errorDetails.errorCode === undefined) {
			this.trackPageEvent(null, {
				event_trigger: MODAL_MESSAGES.EVENT_TRIGGER,
				modal_type: errorDetails.modalType,  // (success, warning, notify, error)
				modal_message: errorDetails.modalMessage
			});
		} else {
			this.trackPageEvent(null, {
				event_trigger: MODAL_MESSAGES.EVENT_TRIGGER,
				modal_type: errorDetails.modalType,  // (success, warning, notify, error)
				modal_message: errorDetails.modalMessage,
				error_code: errorDetails.errorCode
			});
		}
	}

	public trackHttpErrorPages(errorDetails: any): void {
		if (this.featureFlagService.isEnabled(FeatureFlag.EnableErrorMonitoring)) {
			this.pageViewData = new PageViewData();
			this.pageViewData.event_trigger = HTTP_ERROR_PAGES.EVENT_TRIGGER;
			this.pageViewData.type = HTTP_ERROR_PAGES.TYPE;
			this.pageViewData.error_message = errorDetails.errorMessage;
			this.pageViewData.error_code = errorDetails.errorCode;
			this.view(TealiumService.formatDataForTealium(this.pageViewData));
		}
	}

	public trackApiErrorPages(errorDetails: any): void {
		if (this.featureFlagService.isEnabled(FeatureFlag.EnableErrorMonitoring)) {
			this.pageViewData = new PageViewData();
			this.pageViewData.event_trigger = API_ERROR_PAGES.EVENT_TRIGGER;
			this.pageViewData.type = API_ERROR_PAGES.TYPE;
			this.pageViewData.error_message = errorDetails.errorMessage;
			this.pageViewData.error_code = errorDetails.errorCode;
			this.view(TealiumService.formatDataForTealium(this.pageViewData));
		}
	}

	public setFlashNotifyMsg(errorMsg: string, errorCode: any): void {
		if (this.featureFlagService.isEnabled(FeatureFlag.EnableErrorMonitoring)) {
			const flashNotifyDetails = {
				flashType: FLASH_TYPES.NOTIFY,
				flashMessage: errorMsg,
				errorCode: errorCode,
				pageUrl: window.location.href,
				timestamp: new Date().toISOString()
			};
			this.trackFlashMessages(flashNotifyDetails);
		}
	}

	public setFlashErrorMsg(errorMsg: string, errorCode: any): void {
		if (this.featureFlagService.isEnabled(FeatureFlag.EnableErrorMonitoring)) {
			const flashErrorMsgDetails = {
				flashType: FLASH_TYPES.ERROR,
				flashMessage: errorMsg,
				errorCode: errorCode,
				pageUrl: window.location.href,
				timestamp: new Date().toISOString()
			};
			this.trackFlashMessages(flashErrorMsgDetails);
		}
	}

	public setFlashWarningMsg(errorMsg: string, errorCode: any): void {
		if (this.featureFlagService.isEnabled(FeatureFlag.EnableErrorMonitoring)) {
			const flashWarningMsgDetails = {
				flashType: FLASH_TYPES.WARNING,
				flashMessage: errorMsg,
				errorCode: errorCode,
				pageUrl: window.location.href,
				timestamp: new Date().toISOString()
			};
			this.trackFlashMessages(flashWarningMsgDetails);
		}
	}

	public setFlashSuccessMsg(errorMsg: string, errorCode: any): void {
		if (this.featureFlagService.isEnabled(FeatureFlag.EnableErrorMonitoring)) {
			const flashSuccessMsgDetails = {
				flashType: FLASH_TYPES.SUCCESS,
				flashMessage: errorMsg,
				errorCode: errorCode,
				pageUrl: window.location.href,
				timestamp: new Date().toISOString()
			};
			this.trackFlashMessages(flashSuccessMsgDetails);
		}
	}

	public setModalNotifyMsg(errorMsg: string, errorCode: any): void {
		if (this.featureFlagService.isEnabled(FeatureFlag.EnableErrorMonitoring)) {
			const ModalNotifyDetails = {
				modalType: MODAL_TYPES.NOTIFY,
				modalMessage: errorMsg,
				errorCode: errorCode,
				pageUrl: window.location.href,
				timestamp: new Date().toISOString()
			};
			this.trackModalMessages(ModalNotifyDetails);
		}
	}

	public setModalSuccessMsg(errorMsg: string, errorCode: any): void {
		if (this.featureFlagService.isEnabled(FeatureFlag.EnableErrorMonitoring)) {
			const ModalSuccessDetails = {
				modalType: MODAL_TYPES.SUCCESS,
				modalMessage: errorMsg,
				errorCode: errorCode,
				pageUrl: window.location.href,
				timestamp: new Date().toISOString()
			};
			this.trackModalMessages(ModalSuccessDetails);
		}
	}

	public setModalErrorMsg(errorMsg: string, errorCode: any): void {
		if (this.featureFlagService.isEnabled(FeatureFlag.EnableErrorMonitoring)) {
			const ModalErrorDetails = {
				modalType: MODAL_TYPES.ERROR,
				modalMessage: errorMsg,
				errorCode: errorCode,
				pageUrl: window.location.href,
				timestamp: new Date().toISOString()
			};
			this.trackModalMessages(ModalErrorDetails);
		}
	}

	public setModalErrorNoDataMsg(errorMsg: string): void {
		if (this.featureFlagService.isEnabled(FeatureFlag.EnableErrorMonitoring)) {
			const ModalErrorDetails = {
				modalType: MODAL_TYPES.ERROR,
				modalMessage: MODAL_MESSAGES.EVENT_MESSAGE + errorMsg,
				errorCode: undefined,
				pageUrl: window.location.href,
				timestamp: new Date().toISOString()
			};
			this.trackModalMessages(ModalErrorDetails);
		}
	}

	public setModalWarningMsg(errorMsg: string, errorCode: any): void {
		if (this.featureFlagService.isEnabled(FeatureFlag.EnableErrorMonitoring)) {
			const ModalWarningMsgDetails = {
				flashType: MODAL_TYPES.WARNING,
				flashMessage: errorMsg,
				errorCode: errorCode,
				pageUrl: window.location.href,
				timestamp: new Date().toISOString()
			};
			this.trackModalMessages(ModalWarningMsgDetails);
		}
	}

}

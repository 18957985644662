import { Injectable } from "@angular/core";
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { Router } from "@angular/router";
import { HTTP_STATUS_CODE } from "../interceptors/web-api.enum";
import { Auth0Service } from "../../../app/auth0/auth0.service";
import { AuthenticationService } from "../../../app/services/authentication.service";
import { ICustomer } from "src/app/self-serve/models/store.model";

@Injectable()
export class ErrorAuth0mfaInterceptor implements HttpInterceptor {
	constructor(
		private auth0Service: Auth0Service,
		private authenticationService: AuthenticationService,
		private router: Router
	) { }
	intercept(
		request: HttpRequest<any>,
		next: HttpHandler
	): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			catchError((err) => {
				if (
					err.status === HTTP_STATUS_CODE.UNAUTHORISED ||
					err.status === HTTP_STATUS_CODE.ACCESS_FORBIDDEN
				) {
					this.auth0MfaLogout();
				}
				const error = err.error.message || err.statusText;
				return throwError(error);
			})
		);
	}
	private auth0MfaLogout(): void {
		this.authenticationService.LoggedOut();
		this.auth0Service.logout();
		}
}
